
<template>
  <div>
    <borrow-table
      apiname="borrow/frombranch"
      idname="borrowId"
      :view="view"
      :viewform="viewform"
      :init="init"
      :filednum="0"
      labelname="รายการอนุมัติ"
      :borrowtype="1"
      tabletype="t"
      :borrowFilter="borrowFilter"
    ></borrow-table>
  </div>
</template>

<script>
import BorrowTable from "./Components/BorrowTable.vue";
export default {
  props: {
    borrowFilter: String
  },
  data() {
    return {
      view: [
        "borrowId",
        "approve",
        "fromBranch",
        "toBranch",
        "qty",
        "startDate",
        "endDate",
        "productItemId",
        "borrowNumber",
        "updateBy",
        "updateDateTime"
      ],
      viewform: [
        // { name: "name", label: "ชื่อลูกค้า", form: "text" },
        // { name: "address", label: "ที่อยู่", form: "textarea" },
        // { name: "phone", label: "เบอรโทร", form: "text" },
        // { name: "state", label: "สถานะ", form: "switch" }
      ],
      init: {
        borrowId: "",
        approve: "",
        fromBranch: "",
        toBranch: "",
        qty: 0,
        startDate: null,
        endDate: null,
        productItemId: "",
        borrowNumber: "",
        updateBy: "",
        updateDateTime: null
      }
    };
  },
  components: {
    BorrowTable
  }
};
</script>
<style>
.vs-tabs--btn {
  font-weight: bold;
}
</style>
