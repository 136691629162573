

<template>
  <vx-card style="min-height: 500px !important;">
    <template>
      <!-- REMOVE POP UP -->
      <vs-popup
        class="pprepp"
        classContent="popup-example label-font "
        title="รายละเอียดออเดอร์"
        :active.sync="detailActive"
      >
        <!--  รายละเอียดออเดอร์  -->
        <vs-row style="font-weight:bold; margin-top:30px;" class="row-form" vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="12"
            vs-sm="12"
            vs-xs="12"
            class="form-label px-2"
            style="padding-left: 20px 30px;"
          >รายละเอียดออเดอร์ {{this.removeCode}}</vs-col>
        </vs-row>

        <div v-if="noworderid !== null && noworderid !== '' ">
          <todo-order-detail @saveprepare="saveresult" :key="noworderid" :noworderid="noworderid"></todo-order-detail>
        </div>
      </vs-popup>
      <!-- POP UP -->
    </template>

    <template>
      <!-- POP UP -->
      <vs-popup classContent="popup-example" title="เปลี่ยนสถานะ" :active.sync="popupActive">
        <!--  เปลี่ยนสถานะของรายการเช่าสินค้า  -->

        <vs-row class="row-form" vs-w="12" style="margin-top:30px;margin-bottom:30px;">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="5"
            vs-sm="5"
            vs-xs="5"
            class="form-label px-2"
          >เปลี่ยนสถานะของรายการเช่าสินค้า</vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="6"
            vs-sm="6"
            vs-xs="6"
            class="form-label px-2"
          >
            <div
              :class="[state==='รอคืนชุด' ? 'choose' : 'notChoose']"
              @click="changeStatus('รอคืนชุด')"
            >รับชุดแล้ว</div>
            <div
              :class="[state==='ยกเลิก' ? 'choose' : 'notChoose']"
              @click="changeStatus('ยกเลิก')"
            >ยกเลิก</div>
          </vs-col>
        </vs-row>
        <!--  เปลี่ยนสถานะของรายการเช่าสินค้า  -->

        <vs-row class="row-form" vs-w="12" style="margin-top:30px;margin-bottom:30px;">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="5"
            vs-sm="5"
            vs-xs="5"
            class="form-label px-2"
          >วิธีการชำระเงิน</vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="6"
            vs-sm="6"
            vs-xs="6"
            class="form-label px-2"
          >
            <div
              :class="[paymentMethod==='เงินสด' ? 'choose' : 'notChoose']"
              @click="changePayment('เงินสด')"
            >เงินสด</div>
            <div
              :class="[paymentMethod==='โอนเงิน' ? 'choose' : 'notChoose']"
              @click="changePayment('โอนเงิน')"
            >โอนเงิน</div>
            <div
              :class="[paymentMethod==='บัตรเครดิต' ? 'choose' : 'notChoose']"
              @click="changePayment('บัตรเครดิต')"
            >บัตรเครดิต</div>
          </vs-col>
        </vs-row>

        <vs-button @click="update()" style="margin:10px;" color="success" type="filled">ตกลง</vs-button>
        <vs-button @click="closePanel()" style="margin:10px;" color="danger" type="filled">ยกเลิก</vs-button>
      </vs-popup>
      <!-- POP UP -->
    </template>

    <template>
      <!-- POP UP -->
      <vs-popup classContent="popup-example" title="บันทึกข้อความ" :active.sync="notePopupActive">
        <!--  เปลี่ยนสถานะของรายการเช่าสินค้า  -->
        <!--  Add this to data part  (import vSelect from "vue-select")     "v-select": vSelect  -->
        <!--  :select >>> ...Selected /  :options >>> ...Options -->
        <vs-row class="row-form" vs-w="12" style="margin-top:30px;margin-bottom:30px;">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="5"
            vs-sm="5"
            vs-xs="5"
            class="form-label px-2"
          >บันทึกข้อความ</vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="6"
            vs-sm="6"
            vs-xs="6"
            class="form-label px-2"
          >
            <vs-textarea v-model="remark"></vs-textarea>
          </vs-col>
        </vs-row>

        <vs-button @click="updatenote()" style="margin:10px;" color="success" type="filled">ตกลง</vs-button>
        <vs-button
          @click="closeNotePanel()"
          style="margin:10px;"
          color="danger"
          type="filled"
        >ยกเลิก</vs-button>
      </vs-popup>
      <!-- POP UP -->
    </template>
    <!-- HEADER -->
    <vs-row vs-w="12">
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="2"
        vs-sm="2"
        vs-xs="6"
        class="px-2"
      >วันที่รับชุด</vs-col>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="4" vs-sm="4" vs-xs="6">
            <v-date-picker
                style="width:100%;font-size:14px;"
                :locale="{
                  id: 'th',
                  firstDayOfWeek: 2,
                  masks: { L: 'DD/MM/YYYY' }
                }"
                mode="range"
                v-model="calendarData"
              />

      </vs-col>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="6" vs-sm="6" vs-xs="12">
        <vs-row vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="4"
            class="px-2"
          >
            <vs-button @click="searchBtn()" style="width:100%;" color="primary" type="filled">ค้นหา</vs-button>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="4"
            class="px-2"
          >
            <vs-button @click="resetBtn()" style="width:100%;" color="warning" type="filled">reset</vs-button>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="4"
            class="px-2"
          ></vs-col>
        </vs-row>
      </vs-col>
    </vs-row>
    <!-- BRANCHES -->
    <!-- *** BEGIN : 2020-11-28 : Search By branches -->

    <!-- *** END : 2020-11-28 : Search By branches -->

    <!-- BRANCHES -->
    <!-- HEADER -->

    <!-- TABLE -->
    <vs-table  style="z-index:1;" pagination max-items="20" search :data="orders">
      <template style="z-index:1 !important;" slot-scope="{data}">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>

            <div  class = "grid-layout-container alignment-block" style = "vertical-align:text-top ;width:100%;" >
             <vs-row vs-w="12">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-lg="1"  vs-sm="1" vs-xs="1" >
                    <div v-if="pickupFilter==='งานค้างอยู่'"  style="text-align:center; width:100%; height:100%; padding-top: 7px; border-radius:5px;" class="px-1 label-btn-red">จัดชุด</div>
                     <div v-else-if="pickupFilter==='เสร็จสิ้น'"  style="text-align:center; width:100%; height:100%; padding-top: 7px; border-radius:5px;" class="px-1 label-btn-green">จัดแล้ว</div>
                 </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-lg="9"  vs-sm="9" vs-xs="9" >
                  <span v-if="pickupFilter === 'งานค้างอยู่'"
                      @click="getRedirectLink(tr.orderHeaderId)"
                      class=" px-2" style="font-size: 1.1rem; font-bold: weight;"
                    >{{tr.orderNumber}} ({{findBranchName(tr.branchId)}})</span>
                    <span v-else
                      @click="getRedirectLink(tr.orderHeaderId)"
                      class=" px-2" style="font-size: 1.1rem; font-bold: weight;"
                    >{{tr.orderNumber}} ({{findBranchName(tr.branchId)}})</span>

                </vs-col>
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="1"  vs-sm="1" vs-xs="1" >
                  <vx-tooltip v-if="pickupFilter === 'งานค้างอยู่'" text="กดเพื่อเปลี่ยนสถานะเป็นเสร็จสิ้น" position="bottom" delay=".3s">
                   <feather-icon @click="savePrepared(tr.orderHeaderId)"  icon="CheckCircleIcon" class="p-2  rounded-full text-danger" style="text-align:right; width:30px;height:30px; margin-left: 10px; background: rgba(var(--vs-danger),.15)" ></feather-icon>
                  </vx-tooltip>
                   <vx-tooltip v-else text="งานเสร็จสิ้นแล้ว" position="bottom" delay=".3s">
                    <feather-icon   icon="CheckCircleIcon" class="p-2  rounded-full text-success" style="text-align:right; width:30px;height:30px; margin-left: 10px; background: rgba(var(--vs-success),.15)" ></feather-icon>
                   </vx-tooltip>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="1"  vs-sm="1" vs-xs="1" >
                   <vx-tooltip  text="พิมพ์ใบจัดชุด" position="bottom" delay=".3s">
                    <feather-icon  @click="getRedirectToPrint(tr.orderHeaderId)" icon="PrinterIcon" class="p-2  rounded-full text-primary" style="text-align:right; width:30px;height:30px; margin-left: 10px; background: rgba(var(--vs-primary),.15)" ></feather-icon>
                   </vx-tooltip>
                </vs-col>
             </vs-row>
            </div>


            <br />
            <br />
            <i
              style="font-size:18px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px; "
              class="material-icons iconhover"
            >face</i>
            <span class="secondline">{{tr.customerName}}</span>&nbsp;
            <i
              style="font-size:18px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px; "
              class="material-icons iconhover"
            >phone_iphone</i>
            <span class="secondline">{{tr.customerPhone}}</span>&nbsp;&nbsp;
            <i
              style="font-size:18px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px; "
              class="material-icons iconhover"
            >calendar_today</i>
            <span
              class="secondline"
            >รับชุด {{formatDate(tr.pickupDate)}} ~ คืนชุด {{formatDate(tr.returnDate)}}</span>
            <br />
            <br />
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <!-- TABLE -->

    <br />
  </vx-card>
</template>

<script>
import Prism from "vue-prism-component";
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import shapeFormat from "../../mixins/shapeFormat.js";
import TodoOrderDetail from "./TodoOrderDetail.vue";
// window.addEventListener("load", function() {
//   var x = document.getElementsByClassName("tr-expand ");
//   for (var ii = 0; ii < x.length; ii++) {
//     x[ii].innerHTML = "12";
//   }
// });
export default {
  mixins: [shapeFormat],
  props: {
    pickupFilter: String
  },
  data() {
    return {
      calendarData: {},
      componentKey: 0,
      noworderid: "",
      detailActive: false,
      removeCode: "",
      removeId: "",
      searchDateEnd: null,
      original: [],
      searchlist: [],
      permission: "",

      allDetail: [],
      deh: [],
      currentBranch: null,
      currentUser: "guest",
      roles: [],
      takenote: "",
      takenoteIndex: 0,
      theIndex: 0,
      // >>>>>>>> BRANCH INFO >>>>>>>>
      branchCheckbox: [],
      branches: ["ลาดพร้าว", "เมืองทองธานี"],
      allBranch: [],
      branchSelect: [],
      // >>>>>>>> UPDATE INFO >>>>>>>>
      paymentMethod: "ไม่ระบุ",
      receivedBy: "",
      state: "รอรับชุด",

      // >>>>>>>> UPDATE INFO >>>>>>>>
      remark: "",

      // >>>>>>>> UPDATE INFO >>>>>>>>

      statusToReturn: { label: "รับชุดแล้ว", value: "รับชุดแล้ว" },
      statusToReturnOption: [
        { label: "รับชุดแล้ว", value: "รับชุดแล้ว" },
        { label: "ยกเลิก", value: "ยกเลิก" }
      ],
      notePopupActive: false,
      popupActive: false,
      searchDate: null,
      selectedList: [],
      selectAll: false,
      selectStatus: 0, // {0: ไม่เลือกเลย  1: เลือกทั้งหมด 2: เลือกบางอัน}
      fromSelectAll: false,
      date: null,
      pickupDate: null,

      payment_select: { label: "เงินสด", value: "เงินสด" },
      payment_option: ["ไม่ระบุ", "เงินสด", "โอนเงิน", "Alipay/wechat", "บัตรเครดิต"],
      tableList: [
        "vs-th: Component",
        "vs-tr: Component",
        "vs-td: Component",
        "thread: Slot",
        "tbody: Slot",
        "header: Slot"
      ],
      originalList: [],
      orders: []
    };
  },
  computed: {
         //  *** BEGIN : 2020-11-28 : Search By branches
    getCurrentBranch(){
      return this.$store.getters["auth/currentBranch"];
    },
    getMyBranches(){
      // console.log('@@@@PICKUP myBranches >>> ',this.$store.getters["auth/myBranches"]);
      return this.$store.getters["auth/myBranches"];
    },
    myPermission(){
      // console.log('@@@@PICKUP myPermission >>> ',this.$store.getters["auth/permission"]);

      return this.$store.getters["auth/permission"];
    }
     //  *** BEGIN : 2020-11-28 : Search By branches
  },
  async mounted() {
    this.currentUser = JSON.parse(localStorage.getItem("username"));
    this.roles = JSON.parse(localStorage.getItem("roles"));

    this.permission = this.checkPermission(this.roles);

    this.allBranch = await this.loadBranch();

    // var branch = null;
    var ord = [];

    // if (this.permission === "admin") {
    //   ord = await this.loadAllOrderShare();

    //   for (var m = 0; m < this.allBranch.length; m++) {
    //     this.branchSelect[m] = true;
    //   }
    // } else {
    //   //  *** BEGIN : 2020-11-28 : Search By branches
    //   ord = await this.loadOrderByBranchShared(this.getMyBranches);
    //   //  *** END : 2020-11-28 : Search By branches

    //   // branch = await this.findCurrentBranch(this.roles);
    //   // ord = await this.loadBranchOrderPickupdateSortShare(branch.branchId);
    //   // console.log("branch >>>> ", branch);
    // }
      //  *** BEGIN : 2020-12-04 : load just branch occupied
      ord = await this.loadOrderByBranchObjectShared(this.getCurrentBranch);
      //  *** END : 2020-12-04 : load just branch occupied

    // >>>>>>>>>>>>> INIT >>>>>>>>>>>>>>>>>>

    //* >>>>>>>> FILTER FOR NEXT DAY

    // var tomorrow = getdate(new Date(), 1);
    // var tmr = this.formatDate(new Date(tomorrow));
    // var theorder = await ord.filter(
    //   x => x.orderStatus === "รอรับชุด" && x.pickupDate === tmr
    // );
    //* >>>>>>>> FILTER FOR NEXT DAY
    var theorder = null;
    if (this.pickupFilter === "งานค้างอยู่") {
      theorder = await ord.filter(
        x => x.orderStatus === "รอรับชุด" && x.prepareStatus !== "y"
      );
    } else {
      theorder = await ord.filter(
        x => x.orderStatus === "รอรับชุด" && x.prepareStatus === "y"
      );
    }

    var sorted = await this.ascendingDateSortShare(theorder, "pickupDate");

    this.orders = await sorted;
    this.original = await sorted;
    this.searchlist = await sorted;

    //  *** BEGIN : 2020-11-28 : Search By branches
     this.branchCheckbox =  this.getMyBranches;
    //  *** END : 2020-11-28 : Search By branches

    // >>>>>>>>>>>>> INIT >>>>>>>>>>>>>>>>>>

    // this.allDetail = await this.loadAllDetailShare();

    // for (var l = 0; l < this.orders.length; l++) {
    //   var list = await this.allDetail.filter(
    //     x => x.orderHeaderId === this.orders[l].orderHeaderId
    //   );
    //   this.orders[l].detail = await list;
    // }

    // console.log("all ORDER >>>>>>>>>> ", this.orders);
  },

  methods: {

    //  *** BEGIN : 2020-12-07 : Save preparing

    async savePrepared(orderid) {
      this.$vs.loading({ type: "radius" });
      // >>>> START TO SAVE HEADER
      var res = null;
      try {
        res = await this.$http.put(
          this.$store.state.apiURL + "/api/orderheader/prepareorder/" + orderid,
          {
            updateBy: this.currentUser
          },
          this.$store.state.auth.apiHeader
        );
      } catch (error) {
        res = null;
      }

      if (res !== null) {
        this.orderHeader = await res.data;
        await this.reload();
        setTimeout(() => {
          this.$vs.loading.close();
          this.noticeSuccess("บันทึกเตรียมชุดเรียบร้อยแล้ว");
          this.$emit("saveprepare", true);
        }, 500);
      } else {
        setTimeout(() => {
          this.$vs.loading.close();
          this.noticeError("บันทึกไม่สำเร็จ");
          this.$emit("saveprepare", false);
        }, 500);
      }
    },

    getRedirectToPrint(orderId) {
      window.open("/pages/rec-page/" + orderId, "_blank");
      // return "/pages/rec-page/" + orderId;
    },

    //  *** END : 2020-12-07 : Save preparing

    async saveresult(value) {
      if (value) {
        this.detailActive = false;
        await this.reload();
      } else {
        this.detailActive = true;
      }
    },
    forceRerender() {
      this.componentKey += 1;
    },
    // showdetail(orderid) {
    //   this.openDetailPanel();
    // },
    openDetailPanel(order) {
      this.removeCode = "";
      this.noworderid = "";
      this.detailActive = true;
      this.removeCode = order.orderNumber;
      this.noworderid = order.orderHeaderId;
      // console.log("@@@@@ TODO-PICKUP this.noworderid >>> ", this.noworderid);
    },
    async removeOrder() {
      var resp = null;
      this.$vs.loading({ type: "radius" });
      try {
        resp = await this.$http.delete(
          this.$store.state.apiURL + "/api/orderheader/" + this.removeId,
          this.$store.state.auth.apiHeader
        );
      } catch (err) {
        resp = null;
      }
      if (resp === null) {
        this.noticeError("ลบไม่สำเร็จ");
      } else {
        await this.reload();
        //  *** BEGIN : 2020-11-28 : Search By branches
        this.searchBtn();
        //  *** END : 2020-11-28 : Search By branches
        setTimeout(() => {
          this.$vs.loading.close();
          this.closeRemovePanel();
          this.noticeSuccess("ลบสำเร็จ");
        }, 1000);
      }
    },

    closeDetailPanel() {
      this.detailActive = false;
      this.removeCode = "";
      this.removeId = "";
    },

    adminEdit(event, orderid) {
      event.stopPropagation();
      window.open("/backoffice/rental/" + orderid, "_blank");
    },
    printSticker(event, orderid) {
      event.stopPropagation();
      window.open("/pages/sticker/" + orderid, "_blank");
    },
    editRental(event, orderid) {
      event.stopPropagation();
      window.open("/backoffice/rental-edit/" + orderid, "_blank");
    },
    async loadDetail(orderid) {
      var response;
      try {
        response = await this.$http.get(
          this.$store.state.apiURL + "/api/orderdetail/" + orderid,
          this.$store.state.auth.apiHeader
        );
      } catch (error) {
        this.noticeError(error);
      }

      return await response.data;
    },
    getRedirectLink(orderId) {
      return "/backoffice/order-detail/" + orderId;
    },
    async detailOneOrder(orderId) {
      var list = await this.allDetail.filter(x => x.orderHeaderId === orderId);
      console.log("orderId >>> ", orderId, " list >>> ", list);
      return list;
    },
    theSearch(event, index) {
      this.activeb(event, index);
      this.searchBtn();
    },
    activeb(event, index) {
      // var blist = this.allBranch;
      //  *** BEGIN : 2020-11-28 : Search By branches
      var blist = this.getMyBranches;
      //  ***  END : 2020-11-28 : Search By branches
      this.branchCheckbox = [];

      if (event.target.className == "choose") {
        this.branchSelect[index] = false;
        event.target.className = "notChoose";
      } else {
        event.target.className = "choose";
        this.branchSelect[index] = true;
      }

      // ถ้า branchSelect ใส่ค่า
      for (var p = 0; p < blist.length; p++) {
        if (this.branchSelect[p] === true) {
          this.branchCheckbox.push(blist[p]);
        }
      }
      // console.log("this.branchSelect >>> ", this.branchSelect);
      // console.log("this.branchCheckbox >>> ", this.branchCheckbox);
    },
    searchBtn() {

      if(this.calendarData === null )
      {
        this.calendarData = {};
      }
    if(Object.entries(this.calendarData).length === 0 ){
       this.searchDate = null;
       this.searchDateEnd = null;
     }
      //  *** BEGIN : 2020-11-28 : Search By branches


      if (this.searchDate !== null && this.searchDateEnd !== null) {
        var searchDate = this.formatDate(this.searchDate);
        var searchDateEnd = this.formatDate(this.searchDateEnd);
        if (searchDate && searchDateEnd) {
          this.orders = this.searchByDate(
            this.orders,
            searchDate,
            searchDateEnd,
            "pickupDate"
          );
        }
        // console.log("AFTER searchByDate >>> ", this.orders);
      }



    //   var list = [];
    //   var list2 = [];
    //   this.orders = [];
    //   var searchdate = this.formatDate(this.searchDate);
    //   var searchdateEnd = this.formatDate(this.searchDateEnd);
    //   // console.log("searchdateEnd >>> ", searchdateEnd);

    //   var startNull = false;
    //   var endNull = false;

    //   if (
    //     this.searchDate === "" ||
    //     this.searchDate === null ||
    //     this.searchDate === undefined
    //   ) {
    //     startNull = true;
    //   }

    //   if (
    //     this.searchDateEnd === "" ||
    //     this.searchDateEnd === null ||
    //     this.searchDateEnd === undefined
    //   ) {
    //     endNull = true;
    //   }



    //   // console.log("startNull >>> ", startNull, " endNull >>> ", endNull);
    //   // console.log(
    //   //   "this.searchDate >>> ",
    //   //   this.searchDate,
    //   //   " this.searchDateEnd >>> ",
    //   //   this.searchDateEnd
    //   // );

    //   var searchList = [];

    //   if (startNull && endNull) {
    //     searchList = this.original;
    //     // console.log("BOTH NULL");
    //   } else {
    //     // console.log("Either one NULL");
    //     list = this.original;
    //     var tmplist = [];
    //     if (!startNull) {
    //       tmplist = list.filter(
    //         s => this.formatDate(s.pickupDate) >= searchdate
    //       );
    //       // console.log("startNull NOT NULL");
    //     } else {
    //       tmplist = list;
    //     }

    //     // console.log("AFTER SEARCH DATE >>>> ", tmplist);

    //     list2 = tmplist;
    //     var tmplist2 = [];
    //     // console.log("******* END NULL : ", endNull);
    //     if (!endNull) {
    //       tmplist2 = list2.filter(
    //         e => this.formatDate(e.pickupDate) <= searchdateEnd
    //       );
    //       // console.log("endNull NOT NULL");
    //     } else {
    //       tmplist2 = list2;
    //     }

    //     // console.log("AFTER SEARCH DATE END >>>> ", tmplist2);

    //     searchList = tmplist2;
    //   }

    //   // console.log(
    //   //   "@ CHECK POINT  branchCheckbox.length  >>> ",
    //   //   this.branchCheckbox.length
    //   // );

    //   if (this.branchCheckbox.length >= 1) {
    //     for (var k = 0; k < searchList.length; k++) {
    //       for (var j = 0; j < this.branchCheckbox.length; j++) {
    //         if (searchList[k].branchId === this.branchCheckbox[j].branchId) {
    //           this.orders.push(searchList[k]);
    //         }
    //       }
    //     }
    //   }
    },
    resetBtn() {
      this.searchDate = "";
      this.searchDateEnd = "";
      this.orders = this.original;
      this.calendarData = {};
    },
    async reload() {
      // var branch = null;
      var ord = [];
      this.permission = this.checkPermission(this.roles);
      // if (this.permission === "admin") {
      //   ord = await this.loadAllOrderShare();
      // } else {
      //   //  *** BEGIN : 2020-11-28 : Search By branches
      //   ord = await this.loadOrderByBranchShared(this.getMyBranches);
      //   //  *** END : 2020-11-28 : Search By branches

      //   // branch = await this.findCurrentBranch(this.roles);
      //   // ord = await this.loadBranchOrderPickupdateSortShare(branch.branchId);
      // }


      //  *** BEGIN : 2020-12-04 : load just branch occupied
      ord = await this.loadOrderByBranchObjectShared(this.getCurrentBranch);
      //  *** END : 2020-12-04 : load just branch occupied

      // >>>>>>>>>>>>> INIT >>>>>>>>>>>>>>>>>>
      // var theorder = await ord.filter(
      //   x => x.orderStatus === "รอรับชุด" && x.prepareStatus !== "y"
      // );
      var theorder = null;
      if (this.pickupFilter === "งานค้างอยู่") {
        theorder = await ord.filter(
          x => x.orderStatus === "รอรับชุด" && x.prepareStatus !== "y"
        );
      } else {
        theorder = await ord.filter(
          x => x.orderStatus === "รอรับชุด" && x.prepareStatus === "y"
        );
      }

      var sorted = await this.ascendingDateSortShare(theorder, "pickupDate");

      this.orders = await sorted;
      this.searchlist = await sorted;
      this.original = await sorted;

      // >>>>>>>>>>>>> INIT >>>>>>>>>>>>>>>>>>
    },
    changeToReturn: function(event, order, index) {
      event.stopPropagation();
      this.paymentMethod = order.paymentMethod;
      this.orderHeaderId = order.orderHeaderId;
      this.state = order.orderStatus;
      this.theIndex = index;
      this.popupActive = true;
    },
    async update() {
      this.$vs.loading({ type: "radius" });
      // SAVE HEADER

      var ord = {
        paymentMethod: this.paymentMethod,

        updateBy: this.currentUser,
        orderStatus: this.state
      };

      // >>>> START TO SAVE HEADER
      var responseOrder;
      try {
        responseOrder = await this.$http.put(
          this.$store.state.apiURL +
            "/api/orderheader/received/" +
            this.orderHeaderId,
          ord,
          this.$store.state.auth.apiHeader
        );
        if (responseOrder.status === 200) {
          this.reload();
          setTimeout(() => {
            this.$vs.loading.close();
            this.noticeSuccess("ยืนยันข้อมูลเรียบร้อยแล้ว");
          }, 2000);
          this.popupActive = false;
        }
      } catch (error) {
        setTimeout(() => {
          this.$vs.loading.close();
          this.noticeError(error);
        }, 2000);
      }

      // >>>> START TO SAVE HEADER
    },
    resetValue() {
      this.paymentMethod = "ไม่ระบุ";
      this.state = "ไม่ระบุ";
      this.theIndex = -1;
      this.takenoteIndex = -1;
      this.remark = "";
    },
    closePanel() {
      this.popupActive = false;
      this.resetValue();
    },
    changeStatus(state) {
      this.state = state;
    },
    changePayment(pay) {
      this.paymentMethod = pay;
    },
    showPayment(pay) {
      if (pay === "เงินสด" || pay === "โอนเงิน" ||  pay === "Alipay/wechat" || pay === "บัตรเครดิต") {
        return pay;
      } else {
        return "ไม่ระบุ";
      }
    },

    async findCurrentBranch(roles) {
      // >>>>>>> หา branch จาก  role ของ user >>>>>>>>>
      let branch_th = "1";
      var userrole = [];
      userrole = roles.find(x => x.roleName.includes("staff-"));
      if (userrole.length === 0 || userrole === undefined) {
        branch_th = "1";
      } else {
        var role_split = userrole.roleName.split("-");
        if (role_split[1] === null || role_split[1] === undefined) {
          branch_th = "1";
        }
        branch_th = role_split[1];
      }

      // console.log(" branch_th >>>> ", branch_th);

      var allBranches = await this.allBranch;

      var thisBranch = await allBranches.find(a => a.code === branch_th);

      return thisBranch;
    },

    findBranchName(id) {
      var b = this.allBranch.find(i => i.branchId === id);
      // console.log("@ BRANCH NAME >>> ", b, " ID >>> ", id);

      // return b.branchName;
      if (b === null || b === undefined) return "ไม่ระบุ";
      else return b.branchName;
    },

    async loadBranch() {
      var response;
      try {
        response = await this.$http.get(
          this.$store.state.apiURL + "/api/branch",
          this.$store.state.auth.apiHeader
        );
      } catch (error) {
        this.noticeError(error);
        console.log("error", error);
      }

      return await response.data;
    },
    checkPermission(roles) {
      var pm = roles.filter(
        x =>
          x.roleName === "owner" ||
          x.roleName === "admin" ||
          x.roleName === "accountingadmin" ||
          x.roleName === "useradmin"
      );
      if (pm.length >= 1) {
        return "admin";
      } else {
        return "staff";
      }
    },
    confirmToChangeStatus(event) {
      event.stopPropagation();
      // this.orders[this.theIndex].orderStatus = "รับชุดแล้ว";
      // this.orders = this.orders.filter(x => x.orderStatus === "รอรับชุด");

      this.popupActive = false;
    },
    takeNote(event, index) {
      event.stopPropagation();
      console.log("@@@@@ TAKE NOTE >>> ", this.orders[index]);

      this.takenoteIndex = index;
      this.notePopupActive = true;
      this.remark = this.orders[index].remark1;
    },
    async updatenote() {
      this.$vs.loading({ type: "radius" });
      var ordId = this.orders[this.takenoteIndex].orderHeaderId;
      console.log(
        "ordId >>> ",
        ordId,
        " this.takenoteIndex ",
        this.takenoteIndex
      );

      var response;
      try {
        response = await this.$http.put(
          this.$store.state.apiURL + "/api/orderheader/takenote1/" + ordId,
          {
            note: this.remark,
            updateBy: this.currentUser
          },
          this.$store.state.auth.apiHeader
        );

        if (response.status === 200) {
          this.reload();
          setTimeout(() => {
            this.$vs.loading.close();
            this.noticeSuccess("ยืนยันข้อมูลเรียบร้อยแล้ว");
            this.popupActive = false;
            this.closeNotePanel();
          }, 1000);
        }
      } catch (err) {
        this.noticeError(err);
      }
    },
    closeNotePanel() {
      this.notePopupActive = false;
      this.$vs.loading.close();
      this.resetValue();
    },

    selectAllOrders: function(event) {
      event.stopPropagation();

      var ind = 0;

      if (this.selectAll === false && this.selectStatus === 1) {
        this.selectAll = true;
      }
      if (this.selectStatus === 0 || this.selectStatus === 2) {
        // console.log("expected >>> 0 or 2");
        this.selectedList = [];
        for (ind = 0; ind < this.orders.length; ind++) {
          this.orders[ind].selected = true;
          this.selectedList.push(this.orders[ind]);
        }
        this.selectStatus = 1;
      } else if (this.selectStatus === 1) {
        // console.log("expected >>> 1");
        for (ind = 0; ind < this.orders.length; ind++) {
          this.orders[ind].selected = false;
        }
        this.selectedList = [];
        this.selectStatus = 0;
      }
      this.fromSelectAll = true;
      // console.log(
      //   "SelectAll ==> " + this.selectAll + " STATUS  ===> " + this.selectStatus
      // );
      // console.log(this.orders);
      // console.log(this.selectedList);
    },
    addSelectedList(indextt, event) {
      event.stopPropagation();
      // console.log(this.orders[indextt].selected);

      if (!this.orders[indextt].selected) {
        this.orders[indextt].selected = true;
        this.selectedList.push(this.orders[indextt]);
        // console.log("ADDED");
      } else {
        if (this.selectedList) {
          this.orders[indextt].selected = false;
          var foundIndextt = this.selectedList.findIndex(
            x => x.orderNumber === this.orders[indextt].orderNumber
          );
          this.selectedList.splice(foundIndextt, 1);
          // console.log("REMOVED");
        }
      }
      this.orders[indextt].selected = !this.orders[indextt].selected;
      // console.log(
      //   "SelectAll ==> " +
      //     this.selectAll +
      //     "TRUE STATUS  ===> " +
      //     this.selectStatus
      // );
      // console.log(this.orders);
      // console.log(this.selectedList);
    }
  },
  created: function() {},
  components: {
    Prism,
    Datepicker,
    "v-select": vSelect,
    TodoOrderDetail
  },
  watch: {
    async getCurrentBranch(){
      await this.reload();
      this.searchBtn();
      // console.log('curent brach changed !!! >>> ',value.branchName);

    },

    calendarData: {
      handler(value) {
        this.searchDate = this.formatDate(value.start);
        this.searchDateEnd = this.formatDate(value.end);
      },
      deep: true
    },
    async noworderid() {
      await this.reload();
    },
    orders: {
      handler() {},
      deep: true
    },

    selectedList: function() {
      var ordersLength = this.orders.length;
      var selectedLength = this.selectedList.length;

      if (ordersLength == undefined) {
        this.selectStatus = 0;
        if (this.fromSelectAll === false) this.selectAll = false;
        // console.log("IN ordersLength == undefined");

        // console.log("SelectAll");
      }
      if (selectedLength == undefined) {
        this.selectStatus = 0;
        if (this.fromSelectAll === false) this.selectAll = false;
        // console.log("IN selectedLength == undefined)");
      }

      if (ordersLength == selectedLength && selectedLength > 0) {
        this.selectStatus = 1;
        if (this.fromSelectAll === false) this.selectAll = true;
        // console.log("IN ordersLength == selectedLength && selectedLength > 0");
        // console.log("SelectAll");
      } else {
        if (selectedLength == 0 || selectedLength == undefined) {
          this.selectStatus = 0;
          if (this.fromSelectAll === false) this.selectAll = false;
          // console.log("IN selectedLength == 0 || selectedLength == undefined");
        } else {
          this.selectStatus = 2;
          if (this.fromSelectAll === false) this.selectAll = true;
          // console.log("IN ELSE");
          // console.log("Select some");
        }
      }
      // console.log(this.orders);
      this.fromSelectAll = false;
      // console.log(
      //   "WACTCH selectedList >>> this.selectAll : " +
      //     this.selectAll +
      //     "  >>> this.selectStatus :  " +
      //     this.selectStatus
      // );

      // console.log(this.orders);
      // console.log(this.selectedList);
    }
    // branchCheckbox: function() {
    //   this.searchBtn();
    // }
  }
};
</script>

<style scoped>
.vs-con-tbody {
  z-index: 1;
}
input {
  width: 100%;
}
td {
  font-size: 12px;
}
.td-check {
  display: none;
}
.vs-select--input {
  font-size: 12px;
}
.input-select {
  font-size: 12px !important;
}

.iconhover:hover {
  background-color: #d0f3ff;
}

.vs-select--options span {
  font-size: 12px !important;
  width: 120%;
}
.changetoreturn {
  background: #f5a623;
  border: 1px solid #ad6c02;
  border-radius: 16px;
  font-family: SukhumvitSet-Bold;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  padding: 3px 10px;
  margin-top: 10px;
}

/* v-select  */
.clear {
  display: none;
}

.vs__selected-options .form-control {
  display: none !important;
}
.dropdown-menu {
  margin-top: 5 !important;
  background-color: white;
}
.selected-tag {
  width: 100% !important;
}
.vs__actions {
  display: none !important;
}
.bluetext {
  font-family: SukhumvitSet-Bold;
  font-size: 15px;
  color: #2372ce;
  letter-spacing: 0;
}
.secondline {
  font-family: SukhumvitSet-Bold;
  font-size: 12px;
  font-weight: bold;
}
.con-vs-popup .vs-popup {
  width: 1000px !important;
}
.pprepp > .vs-popup {
  width: 900px !important;
}

/* v-select  */
</style>
