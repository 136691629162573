
<template>
  <vx-card style="min-height: 500px !important;" >
    <!-- REMOVE POP UP -->
    <template>
      <!-- REMOVE POP UP -->
      <vs-popup
        classContent="popup-example label-font"
        title="ลบข้อมูล"
        :active.sync="removePopupActive"
      >
        <!--  รหัส  -->
        <vs-row style="font-weight:bold; margin-top:30px;" class="row-form" vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="12"
            vs-sm="12"
            vs-xs="12"
            class="form-label px-2"
            style="padding-left: 20px 30px;"
          >ลบ{{labelname}} {{this.theInfo}}</vs-col>
        </vs-row>

        <vs-row vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="12"
            vs-sm="12"
            vs-xs="12"
          >
            <vs-button @click="remove()" style="margin:10px;" color="success" type="filled">ตกลง</vs-button>
            <vs-button
              @click="closeRemovePanel()"
              style="margin:10px;"
              color="danger"
              type="filled"
            >ยกเลิก</vs-button>
          </vs-col>
        </vs-row>
      </vs-popup>
      <!-- POP UP -->
    </template>
    <!-- UPDATE POP UP -->
    <template>
      <!-- UPDATE POP UP -->
      <vs-popup
        classContent="popup-example label-font"
        title="เปลี่ยนข้อมูล"
        :active.sync="updatePopupActive"
      >
        <vs-row
          v-for="(ff, index) in this.viewform"
          :key="index"
          style="font-weight:bold; margin-top:30px;"
          class="row-form"
          vs-w="12"
        >
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="4"
            class="form-label px-2"
          >{{ff.label}}</vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="6"
            vs-sm="6"
            vs-xs="6"
            class="form-label px-2"
          >
            <vs-input v-if="ff.form === 'text'" class="w-full" v-model="item[ff['name']]" />
            <vs-textarea v-else-if="ff.form === 'textarea'" v-model="item[ff['name']]"></vs-textarea>
            <vs-switch
              v-else-if="ff.form === 'switch'"
              style="width:50px;"
              color="success"
              v-model="item[ff['name']]"
            >
              <span slot="on">เปิด</span>
              <span slot="off">ปิด</span>
            </vs-switch>
          </vs-col>
        </vs-row>

        <vs-row vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="12"
            vs-sm="12"
            vs-xs="12"
          >
            <vs-button @click="update()" style="margin:10px;" color="success" type="filled">ตกลง</vs-button>
            <vs-button
              @click="closeUpdatePanel()"
              style="margin:10px;"
              color="danger"
              type="filled"
            >ยกเลิก</vs-button>
          </vs-col>
        </vs-row>
      </vs-popup>
      <!-- POP UP -->
    </template>
    <!-- CREATE POP UP -->
    <template>
      <vs-popup
        classContent="popup-example label-font"
        title="เพิ่มข้อมูล"
        :active.sync="createPopupActive"
      >
        <vs-row
          v-for="(ff, index) in this.viewform"
          :key="index"
          style="font-weight:bold; margin-top:30px;"
          class="row-form"
          vs-w="12"
        >
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="4"
            class="form-label px-2"
          >{{ff.label}}</vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="6"
            vs-sm="6"
            vs-xs="6"
            class="form-label px-2"
          >
            <vs-input v-if="ff.form === 'text'" class="w-full" v-model="item[ff['name']]" />
            <vs-textarea v-else-if="ff.form === 'textarea'" v-model="item[ff['name']]"></vs-textarea>
            <vs-switch
              v-else-if="ff.form === 'switch'"
              style="width:50px;"
              color="success"
              v-model="item[ff['name']]"
            >
              <span slot="on">เปิด</span>
              <span slot="off">ปิด</span>
            </vs-switch>
          </vs-col>
        </vs-row>
        <vs-row vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="12"
            vs-sm="12"
            vs-xs="12"
          >
            <vs-button @click="create()" style="margin:10px;" color="success" type="filled">ตกลง</vs-button>
            <vs-button
              @click="createPopupActive=false"
              style="margin:10px;"
              color="danger"
              type="filled"
            >ยกเลิก</vs-button>
          </vs-col>
        </vs-row>
      </vs-popup>
      <!-- POP UP -->
    </template>
    <!-- HEADER -->
    <div class="grid-layout-container alignment-block" style="vertical-align:text-top ;width:100%;">
      <!--------------------------------------------------------------------->
      <!--  theline  -->
      <vs-row class="row-form" vs-w="12" style="margin-top:30px;">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="4"
          vs-sm="4"
          vs-xs="4"
        >
          <h3 style="margin-bottom: 20px;">{{labelname}}</h3>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="4"
          vs-sm="4"
          vs-xs="4"
        ></vs-col>

        <!-- SAVE ITEM -->
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-lg="2"
          vs-sm="2"
          vs-xs="2"
        ></vs-col>
        <!-- SAVE ITEM -->
        <!-- BACK to STOCK -->
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-lg="2"
          vs-sm="2"
          vs-xs="2"
        ></vs-col>
        <!-- BACK to STOCK -->
      </vs-row>

      <!--------------------------------------------------------------------->
    </div>
    <!-- HEADER -->
    <!-- TODO TABLE -->
    <vs-table v-if="tabletype==='t'" pagination max-items="20" search :data="list">
      <template slot-scope="{data}">
        <vs-tr style="font-size: 11px; " :key="indextr" v-for="(tr, indextr) in data">
          <vs-td style="width:10% ; padding-left:20px;">

            <br />
            <span
              style="font-size:12px; font-weight:bold;"
            >{{ getProductName(tr["productItemId"]) }}</span>
            <br />
            <span>({{ getProductCode(tr["productItemId"]) }})</span>
          </vs-td>

          <vs-td style="width:10% ; font-weight:bold; ">
            {{ tr["borrowNumber"] }}
            <br />
            <div
              style="width:70% !important; text-align: center; "
              class="p-2"
              :class="getApproveClassName(tr['approve'])"
            >{{ getApproveText(tr['approve']) }}</div>
          </vs-td>
          <vs-td style="width:30% ">
            <i
              style="font-size:18px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px; "
              class="material-icons iconhover"
            >calendar_today</i>
            <span
              class="secondline"
            >รับชุด {{formatDate(tr.startDate)}} ~ คืนชุด {{formatDate(tr.endDate)}}</span>
            <br />
            <br />
            <i
              style="font-size:18px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px; "
              class="material-icons iconhover"
            >store_mall_directory</i>
            <span
              class="secondline"
            >{{ getBranchName(tr["fromBranch"]) }} ~ {{ getBranchName(tr["toBranch"]) }}</span>
          </vs-td>

          <vs-td style="width:20% ; ">
            ผู้ขอ: {{ tr.updateBy }}
            <br />
            วันที่: {{ formatDate(tr.updateDateTime) }}
          </vs-td>


          <!-- *** 2. กรณีคืนสถานะหลังไม่ APPROVE *** -->
          <vs-td
            v-if=" tr['approve'] === 2"
            style="width:15% text-align:right;"
          >
            <div v-if="borrowtype===1">
              <div @click="rollback(tr['borrowId'])" class="sm-btn-cyan">คืนสถานะ</div>
            </div>
          </vs-td>
          <!-- *** 1. กรณีรอคืนชุดกดอะไรไม่ได้ รอคนยืมขอกดกลับมา *** -->
          <vs-td
            v-else-if="tr['approve'] === 1"
            style="width:15% text-align:right;"
          >
            <div v-if="borrowtype===1">
               <!-- คนที่ขอยืมชุดกดไม่ได้ -->
              <div @click="wait_to_return(tr['borrowId'])" v-if="tr['fromBranch'] === getCurrentBranch.branchId" class="sm-btn-yellow" >รอรับชุด</div>
              <div v-else style="color:#f5a623; font-size: 1.2rem; font-weight: bold;" >รอส่งชุด</div>
            </div>

          </vs-td>
          <vs-td v-else style="width:15% text-align:right;">
              <!-- <div
                v-if="getProductName(tr['productItemId']) === 'ไม่มีข้อมูล'" > remove</div> -->
            <div v-if="borrowtype===1">
              <!-- <div @click="approve(tr['borrowId'],1)" class="sm-btn-blue">อนุมัติ</div>
              <div @click="approve(tr['borrowId'],2)" class="sm-btn-black">ไม่อนุมัติ</div> -->
              <div v-if="tr['approve'] === 0" @click="approve(tr['borrowId'],1)" class="sm-btn-green">อนุมัติ</div>
              <div v-if="tr['approve'] === 0" @click="approve(tr['borrowId'],2)" class="sm-btn-black">ไม่อนุมัติ</div>

              <div v-if="tr['approve'] === 3 && tr['fromBranch'] === getCurrentBranch.branchId"   style="color:#4A90E2; font-size: 1.2rem; font-weight: bold;" >รอส่งคืน</div>
              <div v-if="tr['approve'] === 3 && tr['toBranch'] === getCurrentBranch.branchId"  @click="getback(tr['borrowId'])"  class="sm-btn-blue" >รอรับคืน</div>

            </div>

          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <!-- TODO TABLE -->

    <!-- TABLE -->

    <!-- NORMAL TABLE -->
    <vs-table v-else pagination max-items="20" search :data="list">
      <template slot="thead">
        <vs-th>รูปภาพ</vs-th>
        <vs-th>เลขขอยืม</vs-th>
        <vs-th>ข้อมูลสินค้า</vs-th>
        <vs-th>วันรับ</vs-th>
        <vs-th>วันคืน</vs-th>
        <vs-th v-if="borrowtype===1" >สาขาที่ขอมา</vs-th>
        <vs-th v-if="borrowtype===2" >สาขาอนุมัติ</vs-th>
        <vs-th sort-key="updateBy">วันที่ขอ</vs-th>
        <vs-th style="text-align:end;">การจัดการ</vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr style="font-size: 11px; " :key="indextr" v-for="(tr, indextr) in data">
          <vs-td style="width:10% ; padding-left:20px;">
            <img
              class="responsive rounded"
              :src="productImage(tr['productItemId'])"
              alt="user-upload"
              style="width:70px;"
            />
          </vs-td>

          <vs-td style="width:10% ">
            {{ tr["borrowNumber"] }}
            <br />
            <div
              style="cursor:pointer; width:75%; text-align:center; border-radius:20px;"
              class="py-2"
              :class="getApproveClassName(tr['approve'])"
            >{{ getApproveText(tr['approve']) }}</div>
          </vs-td>
          <vs-td style="width:12% ">
            <span
              style="font-size:12px; font-weight:bold;"
            >{{ getProductName(tr["productItemId"]) }}</span>
            <br />
            <span>({{ getProductCode(tr["productItemId"]) }})</span>
          </vs-td>
          <vs-td style="width:10%">{{formatDate(tr["startDate"])}}</vs-td>
          <vs-td style="width:10%">{{formatDate(tr["endDate"])}}</vs-td>
          <vs-td  v-if="borrowtype===1" style="width:10% ">{{ getBranchName(tr["fromBranch"]) }}</vs-td>
          <vs-td  v-if="borrowtype===2"  style="width:10% ">{{ getBranchName(tr["toBranch"]) }}</vs-td>


          <vs-td style="width:10% ; ">
           {{ formatDate(tr.updateDateTime) }}
            <br />
            {{ tr.updateBy }}
          </vs-td>

          <vs-td
            v-if=" tr['approve'] === 2"
            style="width:15% text-align:right;"
          >
            <div v-if="borrowtype===1">
              <div @click="rollback(tr['borrowId'])" class="sm-btn-cyan">คืนสถานะ</div>
            </div>
          </vs-td>
          <vs-td
            v-else-if="tr['approve'] === 1"
            style="width:15% text-align:right;"
          >
            <div v-if="borrowtype===1">
               <!-- คนที่ขอยืมชุดกดไม่ได้ -->
              <div style="color:#f5a623; font-size: 1.2rem; font-weight: bold;" >รอส่งชุด</div>
            </div>
            <div v-if="borrowtype===2">
              <!-- คนที่ขอยืมชุดไปต้องกดแจ้งว่าส่งคืนแล้ว -->
              <div @click="wait_to_return(tr['borrowId'])" class="sm-btn-yellow">รอรับชุด</div>
            </div>
          </vs-td>
          <vs-td v-else style="width:15% text-align:right;">
            <div v-if=" borrowtype===1 && getProductName(tr['productItemId']) !== 'ไม่มีข้อมูล'">
              <div v-if="tr['approve'] === 0" @click="approve(tr['borrowId'],1)" class="sm-btn-green">อนุมัติ</div>
              <div v-if="tr['approve'] === 0" @click="approve(tr['borrowId'],2)" class="sm-btn-black">ไม่อนุมัติ</div>
              <div v-if="tr['approve'] === 3" @click="getback(tr['borrowId'])" class="sm-btn-blue">รอรับคืน</div>

              <!-- <div v-if="tr['approve'] === 3 && tr['toBranch'] === getCurrentBranch.branchId"  @click="getback(tr['borrowId'])"  class="sm-btn-blue" >รอรับคืน</div> -->

            </div>
            <div v-if=" borrowtype===2" >
              <div v-if="tr['approve'] === 3"   style="color:#4A90E2; font-size: 1.2rem; font-weight: bold;" >รอส่งคืน</div>
              <div @click="removeBorrow(tr['borrowId'])" v-if="getProductName(tr['productItemId']) === 'ไม่มีข้อมูล'" class="sm-btn-red" >ลบ</div>
            </div>

          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <!-- NORMAL TABLE -->
    <!-- TABLE -->
  </vx-card>
</template>

<script>
import shapeFormat from "../../../mixins/shapeFormat.js";
export default {
  props: {
    // list: Array,
    apiname: String,
    idname: String,
    labelname: String,
    view: Array,
    viewform: Array,
    init: Object,
    borrowtype: Number,
    borrowFilter: String,
    tabletype: String // ใช้เป็น 'n': table ธรรมดา หรือ 't': TODO
  },
  mixins: [shapeFormat],
  data() {
    return {
      branches: [],
      product: [],
      item: {},
      currentUser: "guest",
      theInfo: "",
      theState: true,
      theIndex: "",
      theID: "",
      configitem: "",
      dec: "",
      createPopupActive: false,
      updatePopupActive: false,
      removePopupActive: false,
      selected: [],
      tableList: [
        "vs-th: Component",
        "vs-tr: Component",
        "vs-td: Component",
        "thread: Slot",
        "tbody: Slot",
        "header: Slot"
      ],
      list: []
    };
  },
  async created() {
    var resBranch = await this.$http.get(
      this.$store.state.apiURL + "/api/branch",
      this.$store.state.auth.apiHeader
    );
    this.branches = await resBranch.data;

    var prod = await this.$http.get(
      this.$store.state.apiURL + "/api/productitem",
      this.$store.state.auth.apiHeader
    );

    this.product = await prod.data;

    // console.log("this.product >>> ", this.product);
  },
    computed: {
     //  *** BEGIN : 2020-11-28 : Search By branches
    getCurrentBranch(){
      return this.$store.getters["auth/currentBranch"];
    },
    getMyBranches(){
      // console.log('@@@@PICKUP myBranches >>> ',this.$store.getters["auth/myBranches"]);
      return this.$store.getters["auth/myBranches"];
    },
    myPermission(){
      // console.log('@@@@PICKUP myPermission >>> ',this.$store.getters["auth/permission"]);

      return this.$store.getters["auth/permission"];
    }
     //  *** BEGIN : 2020-11-28 : Search By branches
  },
    watch: {
      async getCurrentBranch(){
        await this.reload();

      // await this.resetAll();

    }
  },
  methods: {
    async removeBorrow(id){

      this.$vs.loading({ type: "radius" });
      try {
        await this.$http.delete(
          this.$store.state.apiURL + "/api/borrow/" + id,
          this.$store.state.auth.apiHeader
        );
        this.reload();
      } catch (err) {
             this.$swal({
              icon: "error",
              title: "เกิดข้อผิดพลาด"
              // text: 'Something went wrong!',
            });
      }
      this.$vs.loading.close();
    },
    async approve(id, appr) {
      this.$vs.loading({ type: "radius" });
      var res = null;
      try {
        res = await this.$http.put(
          this.$store.state.apiURL + "/api/borrow/" + id,
          {
            approve: appr,
            updateBy: this.currentUser
          },
          this.$store.state.auth.apiHeader
        );
      } catch (err) {
        res = null;
      }
      if (res !== null) {
        this.reload();
        setTimeout(() => {
          this.noticeSuccess("อนุมัติเสร็จสิ้น");
          this.$vs.loading.close();
        }, 500);
      } else {
        setTimeout(() => {
          this.noticeError("อนุมัติไม่สำเร็จ");
          this.$vs.loading.close();
        }, 500);
      }
    },

    async wait_to_return(id) {

      this.$vs.loading();
      var res = null;
      try {
        res = await this.$http.put(
          this.$store.state.apiURL + "/api/borrow/return/" + id,
          {
          },
          this.$store.state.auth.apiHeader
        );
      } catch (err) {
        res = null;
      }
      if (res !== null) {
        this.reload();
        setTimeout(() => {
          this.noticeSuccess("แจ้งรับชุดคืนเรียบร้อยแล้ว");

          this.$vs.loading.close();
        }, 500);
      } else {
        setTimeout(() => {
          this.noticeError("แจ้งรับชุดคืนไม่สำเร็จ");
          this.$vs.loading.close();
        }, 500);
      }
    },

    async getback(id) {

      this.$vs.loading();
      var res = null;
      try {
        res = await this.$http.put(
          this.$store.state.apiURL + "/api/borrow/getback/" + id,
          {
          },
          this.$store.state.auth.apiHeader
        );
      } catch (err) {
        res = null;
      }
      if (res !== null) {
        this.reload();
        setTimeout(() => {
          this.noticeSuccess("คุณแจ้งคืนสินค้าที่ขอยืมมาเรียบร้อยแล้ว");

          this.$vs.loading.close();
        }, 500);
      } else {
        setTimeout(() => {
          this.noticeError("แจ้งรับชุดคืนไม่สำเร็จ");
          this.$vs.loading.close();
        }, 500);
      }
    },


    async rollback(id) {
      this.$vs.loading({ type: "radius" });
      var res = null;
      try {
        res = await this.$http.put(
          this.$store.state.apiURL + "/api/borrow/rollback/" + id,
          {
            updateBy: this.currentUser
          },
          this.$store.state.auth.apiHeader
        );
      } catch (err) {
        res = null;
      }
      if (res !== null) {
        this.reload();
        setTimeout(() => {
          this.noticeSuccess("คืนสถานะเสร็จสิ้น");
          this.$vs.loading.close();
        }, 500);
      } else {
        setTimeout(() => {
          this.noticeError("คืนสถานะไม่สำเร็จ");
          this.$vs.loading.close();
        }, 500);
      }
    },
    getApproveText(num) {
      switch (num) {
        case 0:
          return "ยังไม่อนุมัติ";

        case 1:
          return "อนุมัติ";
        case 2:
          return "ไม่อนุมัติ";
        case 3:
          return "รอคืน";
        case 4:
          return "คืนแล้ว";
        default:
          return "ยังไม่อนุมัติ";
      }
    },
    getApproveClassName(num) {
      switch (num) {
        case 0:
          return "label-btn-grey";

        case 1:
          return "label-btn-green";
        case 2:
          return "label-btn-red";
        case 3:
          return "label-btn-yellow";
        case 4:
          return "label-btn-blue";
        default:
          return "label-btn-grey";
      }
    },
    getProductName(id) {
      var prd = this.product.find(x => x.productItemId === id);
      if(prd === null || prd === undefined){
        return "ไม่มีข้อมูล";
      }

      return prd.name;
    },
    getProductCode(id) {
      var prd = this.product.find(x => x.productItemId === id);
      if(prd === null || prd === undefined){
        return "ไม่มีข้อมูล";
      }
      return prd.code;
    },

    getBranchName(id) {
      if (this.branches.length <= 0) {
        return "ไม่ระบุ";
      }
      // console.log("this.branches >>> ", this.branches);
      var b = this.branches.find(x => x.branchId === id);
      if (b === null || b === undefined) {
        return "ไม่ระบุ";
      } else {
        return b.branchName;
      }
    },

    productImage(id) {
      var re = this.product.find(x => x.productItemId === id);
      if(re === null || re === undefined || re === {} )
      {
        return this.$store.state.defaultImage;
      }
      if (
        re.mainImage === undefined ||
        re.mainImage === null ||
        re.mainImage === "" ||
        re.mainImage === this.$store.state.imageStore
      ) {
        return this.$store.state.defaultImage;
      } else {
        return this.$store.state.imageStore + re.mainImage;
      }
    },
    productName(id) {
      var res = this.list.find(x => x.productItemId === id);
      if(res === null || res === undefined || res === {} )
      {
        return this.$store.state.defaultImage;
      }
      if (res === null || res === undefined) {
        return "";
      } else {
        return res.productName;
      }
    },
    openCreatePanel() {
      this.createPopupActive = true;
      this.item = {};
      this.resetValue();

      this.item = this.resetviewmodel(this.init, this.view);
      // console.log("VIEW MODEL WHEN CREATE >>> ", this.item);
    },
    closeCreatePanel() {
      this.createPopupActive = false;
      this.resetValue();
    },
    async create() {
      this.$vs.loading({ type: "radius" });
      // console.log("VIEW MODEL WHEN CREATE AFTER CLICK !!! >>> ", this.item);
      /*****  BEGIN: AXIOS HTTP   *****/
      var res = null;
      try {
        res = await this.$http.post(
          this.$store.state.apiURL + "/api/" + this.apiname,
          this.item,
          this.$store.state.auth.apiHeader
        );
      } catch (err) {
        res = null;
      }

      if (res !== null) {
        await this.reload();

        setTimeout(() => {
          this.$vs.loading.close();
          this.closeCreatePanel();
          this.noticeSuccess("บันทึกสำเร็จ");
        }, 1000);
      } else {
        setTimeout(() => {
          this.$vs.loading.close();
          this.noticeError("บันทึกไม่สำเร็จ");
        }, 1000);
      }

      /*****  END: AXIOS HTTP   *****/
    },

    resetValue() {
      this.configitem = "";
      this.dec = "";
      this.theID = "";
      this.theState = true;

      this.item = {};
      // console.log("RESETTTTTTT >>>> ", this.item);
    },

    openUpdatePanel(id, index) {
      this.updatePopupActive = true;
      this.theID = id;
      this.theIndex = index;
      var item = this.list.find(x => x[this.idname] === this.theID);

      if (item != null) {
        this.item = this.viewmodel(item, this.view);
        // console.log("VIEW MODEL WHEN UPDATE >>> ", this.item);
      }
    },

    openRemovePanel(id, index) {
      this.removePopupActive = true;
      this.theID = id;
      this.theIndex = index;

      var item = this.list.find(x => x[this.idname] === this.theID);
      this.removeInfo = item.code;

      if (item != null) {
        this.theInfo = item.code;
        // console.log("ITEM IS EXISTING");
      }
    },

    closeUpdatePanel() {
      this.updatePopupActive = false;
      this.resetValue();
    },
    async changeState(id, index) {
      // console.log("when change state idname >>>> ", this.idname);

      this.theID = id;
      this.theIndex = index;
      // this.list[this.theIndex].state = !this.list[this.theIndex]
      //   .state;
      this.theState = !this.list[this.theIndex].state;

      // console.log(
      //   "this.theID >>> ",
      //   this.theID,
      //   " >>> this.list[this.theIndex].state >>> ",
      //   this.list[this.theIndex].state
      // );

      /*****  BEGIN: AXIOS HTTP   *****/
      var res = null;
      try {
        res = await this.$http.put(
          this.$store.state.apiURL +
            "/api/" +
            this.apiname +
            "/changestate/" +
            this.theID,
          {
            state: this.theState
          },
          this.$store.state.auth.apiHeader
        );
      } catch (err) {
        res = null;
      }

      if (res !== null) {
        this.noticeSuccess("บันทึกสำเร็จ");
        await this.reload();
      } else {
        this.noticeSuccess("บันทึกไม่สำเร็จ");
      }

      /*****  END: AXIOS HTTP   *****/
    },
    async update() {
      this.$vs.loading({ type: "radius" });
      /*****  BEGIN: AXIOS HTTP   *****/

      var res = null;
      try {
        res = await this.$http.put(
          this.$store.state.apiURL + "/api/" + this.apiname + "/" + this.theID,
          this.item,
          this.$store.state.auth.apiHeader
        );
      } catch (err) {
        res = null;
      }

      if (res !== null) {
        await this.reload();

        setTimeout(() => {
          this.$vs.loading.close();
          this.closeUpdatePanel();
          this.noticeSuccess("บันทึกสำเร็จ");
        }, 1000);
      } else {
        setTimeout(() => {
          this.$vs.loading.close();
          this.noticeError("บันทึกไม่สำเร็จ");
        }, 1000);
      }
      /*****  END: AXIOS HTTP   *****/
    },

    closeRemovePanel() {
      this.removePopupActive = false;
    },

    async remove() {
      this.$vs.loading({ type: "radius" });
      /*****  BEGIN: AXIOS HTTP   *****/

      var res = null;
      try {
        res = await this.$http.delete(
          this.$store.state.apiURL + "/api/" + this.apiname + "/" + this.theID,
          this.$store.state.auth.apiHeader
        );
      } catch (err) {
        res = null;
      }

      if (res !== null) {
        await this.reload();

        setTimeout(() => {
          this.$vs.loading.close();
          this.closeRemovePanel();
          this.noticeSuccess("ลบสำเร็จ");
        }, 300);
      } else {
        setTimeout(() => {
          this.$vs.loading.close();
          this.noticeError("ลบไม่สำเร็จ");
        }, 300);
      }
      /*****  END: AXIOS HTTP   *****/
    },
    async reload() {
      var res = null;
      try {
        res = await this.$http.get(
          this.$store.state.apiURL + "/api/" + this.apiname,
          this.$store.state.auth.apiHeader
        );
      } catch (err) {
        res = null;
      }

      if (res !== null) {
        // var templist =  res.data;
        var fulllist = await res.data;

        //*** ตารางปกติ ***//
        // *** รายการที่ถูกยืม ===> ดูเพื่ออนุมัติให้คนอื่น
        if(this.tabletype === 'n' && this.borrowtype === 1){
            fulllist = await fulllist.filter(
              t => t.toBranch === this.getCurrentBranch.branchId
            );
        //*** ตารางปกติ ***//
        // *** รายการที่ขอยืม ===> ดูรายการที่ตัวเองยืมสาขาอื่น
        }else if (this.tabletype === 'n' && this.borrowtype === 2){
            fulllist = await fulllist.filter(
              t => t.fromBranch === this.getCurrentBranch.branchId
            );
        }


        //*** TODO ***//
        if (
          this.borrowFilter !== null &&
          this.borrowFilter !== undefined &&
          this.borrowFilter !== ""
        ) {
          if (this.borrowFilter === "เสร็จสิ้น") {
            fulllist = await fulllist.filter(
              t => (t.approve === 4 || t.approve === 2) && (t.toBranch === this.getCurrentBranch.branchId)
            );
            console.log('เสร็จสิ้น this.getCurrentBranch.branchId >>> ', this.getCurrentBranch.branchName , " กด approve");

          } else {


            var fromList = await fulllist.filter(
              t => t.fromBranch === this.getCurrentBranch.branchId && (t.approve === 1 || t.approve === 3 )
            );

            fulllist = await fulllist.filter(
              t => (t.approve !== 4 && t.approve !== 2) && (t.toBranch === this.getCurrentBranch.branchId)
            );

            for (let ii = 0; ii < fromList.length; ii++) {
              const el = fromList[ii];
              fulllist.push(el);
            }

            fulllist =  await this.descendingDateSortShare(fulllist, "updateDateTime");


            console.log('นอกเหนือจากนั้น this.getCurrentBranch.branchId >>> ', this.getCurrentBranch.branchName , " ยังไม่ approve");

          }
        }

        // หลังแปลงข้อมูลแล้ว
        this.viewlist = await fulllist.map(
          x => (x.branchName = this.getBranchName(x.branchId))
        );
        var viewlist = await this.viewmodellist(fulllist, this.view);



        this.list = await viewlist;
        // console.log('this.list >>>>>>>> ',this.list );

      } else {
        this.noticeError("โหลดข้อมูลไม่สำเร็จ");
      }
    },
    viewmodellist(fulllist, structure) {
      var viewlist = [];
      for (let i = 0; i < fulllist.length; i++) {
        var item = {};
        for (let j = 0; j < structure.length; j++) {
          item[structure[j]] = fulllist[i][structure[j]];
        }
        viewlist.push(item);
      }
      return viewlist;
    },
    viewmodel(fulllist, structure) {
      var item = {};
      for (let j = 0; j < structure.length; j++) {
        item[structure[j]] = fulllist[structure[j]];
      }

      return item;
    },
    resetviewmodel(init, structure) {
      var i = {};
      for (let j = 0; j < structure.length; j++) {
        i[structure[j]] = init[structure[j]];
      }

      return i;
    }
  },

  async mounted() {
    /*****  BEGIN: AXIOS HTTP   *****/
    this.currentUser = JSON.parse(localStorage.getItem("username"));

    // console.log("tabletype >>> ", this.tabletype, " borrowtype >>> ", this.borrowtype);

    await this.reload();

    // console.log("this.product ", this.product);

    /*****  END: AXIOS HTTP   *****/
  }
};
</script>

<style>
.vs-component .vs-switch .vs-switch-success .vs-switch-active {
  width: 50px !important;
}
</style>
