<template>


  <vx-card>

    <vs-row vs-w="12" class="py-4">
        <vs-col class="px-1" vs-type="flex" vs-justify="flex-end" vs-align="center" vs-lg="2" vs-sm="2" vs-xs="8">
           <!-- <h2 :class="[the_status==='เสร็จสิ้น' ? 'cgreen' : 'cred']"  >{{the_status}}</h2> -->
        </vs-col>
        <vs-col  class="px-1 py-2 " :class="[ (the_status === 'งานค้างอยู่') ? 'border-red' : 'border-normal']" vs-type="flex" vs-justify="center" vs-align="center" style="cursor:pointer;" vs-lg="5" vs-sm="5" vs-xs="8">
           <h4 style="width:100%; height:100%; text-align:center;" @click="changePageStatus('งานค้างอยู่')" :class="[(the_status === 'งานค้างอยู่') ? 'cred' : 'cgrey']"  >งานค้างอยู่</h4>
        </vs-col>
        <vs-col  class="px-1 py-2" :class="[ (the_status === 'เสร็จสิ้น') ? 'border-green' : 'border-normal']" vs-type="flex" vs-justify="center" vs-align="center" style="cursor:pointer;" vs-lg="5" vs-sm="5" vs-xs="8">
           <h4 style="width:100%; height:100%; text-align:center;" :class="[ (the_status === 'เสร็จสิ้น') ? 'cgreen' : 'cgrey']" @click="changePageStatus('เสร็จสิ้น')"  >เสร็จสิ้น</h4>
        </vs-col>

    </vs-row>

    <template>
        <div>
          <vs-tabs alignment="fixed" color="rgb(32, 201, 192)">
              <!-- left -->
              <vs-tabs position="left" color="danger">

                <vs-tab @click="the_page='จัดชุดให้ลูกค้า'" label="จัดชุดให้ลูกค้า">
                  <todo-pickup  :key="pickupKey" :pickupFilter="pickupFilter"></todo-pickup>
                </vs-tab>

                <!-- <vs-tab @click="the_page='คำขอยืมชุด'"  label="คำขอยืมชุด">
                  <todo-borrow :key="borrowKey" :borrowFilter="borrowFilter"></todo-borrow>
                </vs-tab> -->

                <vs-tab @click="the_page='รายการโอนเงินคืน'"  label="รายการโอนเงินคืน">
                 <todo-moneyback :key="moneybackKey" :moneybackFilter="moneybackFilter"></todo-moneyback>
                </vs-tab>
              </vs-tabs>
          </vs-tabs>
        </div>
      <!-- </vx-card> -->
    </template>
  </vx-card>
</template>


<script>
import shapeFormat from "../../mixins/shapeFormat.js";
import TodoPickup from "./TodoPickup.vue";
import TodoMoneyback from "./TodoMoneyback.vue";
import TodoBorrow from "./TodoBorrow.vue";

export default {
  props: {
    // list: Array,
    filter: String
  },
  mixins: [shapeFormat],
  data() {
    return {
      the_status: "งานค้างอยู่",
      the_page : "จัดชุดให้ลูกค้า",
      pickupFilter : "งานค้างอยู่",
      pickupKey: 0,
      borrowFilter : "งานค้างอยู่",
      borrowKey: 0,
      moneybackFilter : "งานค้างอยู่",
      moneybackKey: 0,
      remain: 0,
      welcome: "This is your profile"
    };
  },
  methods: {
    thepage(val){
      this.change = val;
      // console.log('@@@##$% thepage ', this.the_page);

    },
    changePageStatus(state){
      // console.log('state >>>>>>. ',state);

      if(state === null || state === undefined || state === "") return ;

      if(this.the_page === "รายการโอนเงินคืน" ){
        this.the_status = state;
        this.moneybackFilter = state;
        ++(this.moneybackKey) ;
      }
      // else if (this.the_page === "คำขอยืมชุด" ){
      //   this.the_status = state;
      //   this.borrowFilter = state;
      //    ++(this.borrowKey) ;
      // }
      else{
        this.the_status = state;
        this.pickupFilter = state;
         ++(this.pickupKey) ;
      }
    }
    ,

  },
  watch : {
    the_page(){

      this.the_status = "งานค้างอยู่";

       if(this.the_page === "รายการโอนเงินคืน" ){

        this.moneybackFilter = this.the_status;
        ++(this.moneybackKey) ;
      }
      // else if (this.the_page === "คำขอยืมชุด" ){
      //   this.borrowFilter = this.the_status;
      //    ++(this.borrowKey) ;
      // }
      else{
        this.pickupFilter = this.the_status;
         ++(this.pickupKey) ;
      }
      // console.log('change the_page >>>> ', value);

    }
  },
  components: {
    TodoPickup,
    TodoMoneyback,
    TodoBorrow
  }
};
</script>

<style >

.border-normal{
  border: solid 2px #f1f1f1;
}
.border-red{
  /* border: solid 2px  rgba(255, 99, 132, 1); */
   background-color:  rgba(255, 99, 132, 1);
   color: white !important;
}
.border-green{
  /* border: solid 2px  rgba(75, 192, 192, 1); */
  background-color: rgba(75, 192, 192, 1);
}
.cgreen{
  /* color: rgba(75, 192, 192, 1); */
  color: white !important;

}
.cred{
  /* color:rgba(255, 99, 132, 1); */
  color: white !important;

}
.cgrey{
  color: #cbcbcb;
}
.con-slot-tabs {
  width: 100% !important;
}
.ul-tabs-left {
  width: 150px !important;
}
.vs-tabs--btn {
  text-align: left;
}

.aui-button{
      color: #505f79;
    background: #f4f5f7;
    border: none;
    outline: 0;
    height: 2.28571429em;
    line-height: 1.57142857em;
}

</style>
